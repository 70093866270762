@font-face {
  font-family: 'bull';
  src: url('../fonts/bull.ttf') format('truetype');
}

@font-face {
  font-family: 'sign';
  src: url('../fonts/sign.ttf') format('truetype');
}
@font-face {
  font-family: 'revuen';
  src: url('../fonts/Milk\ Mango.ttf') format('truetype');
}
.logo {
  width: 100%;
}
.inputbutton {
  top: 20%;
  width: 300px;
  display: flex;
  font-size: 40px;
  height: 90px !important;
  font-family: 'revuen';
  font-size: 25px;
  color: rgb(255,255,255);
  position: fixed;
  justify-content: center;
  align-items: center;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 5px #c300ff;
  stroke: 2px #c300ff;
}
.inputletter {
  top: 15%;
  display: flex;
  font-family: 'revuen';
  font-size: 25px;
  color: rgb(255,255,255);
  position: fixed;
  justify-content: center;
  align-items: center;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 5px #c300ff;
  stroke: 2px #c300ff;
}
.slogan {
  display: none;
  font-family: 'revuen';
  font-size: 25px;
  color: rgb(255,255,255);
  position: fixed;
  justify-content: center;
  align-items: center;
  left: 10%;
  text-align: center;
  transform: translate(-5%, -50%);
  text-shadow: 0 0 5px #c300ff;
  stroke: 2px #c300ff;
}
@media only screen and (min-width: 0px) and (max-width:700px){
  .slogan {
    display: block;
  }
  .inputbutton {
    top: 48%;
    width: 300px;
    display: flex;
    font-family: 'revuen';
    font-size: 12px;
    color: rgb(255,255,255);
    position: fixed;
    justify-content: center;
    align-items: center;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    text-shadow: 0 0 5px #c300ff;
    stroke: 2px #c300ff;
  }
  .inputletter {
    top: 42%;
    display: flex;
    font-family: 'revuen';
    font-size: 18px;
    color: rgb(255,255,255);
    position: fixed;
    justify-content: center;
    align-items: center;
    left: 30%;
    text-align: center;
    transform: translate(-20%, -50%);
    text-shadow: 0 0 5px #c300ff;
    stroke: 2px #c300ff;
  }
  .logo {
    width: 100%;
    height: 48vh;
    object-fit: cover;
    object-position: 57% 43%;
  }
}
body {
  font-family: 'sign', sans-serif;
}
.customizeFont {
  font-family: 'sign', sans-serif;
}
.customizeFont1 {
  font-family: 'bull', sans-serif;
  box-shadow: 0 0 30px 0 #FFF;
  color: aqua;
}

.context {
  width: 100%;
  position: absolute;
  top:50vh;
  
}

.context h1{
  text-align: center;
  color: #fff;
  font-size: 50px;
}


.area{
  background: #4e54c8;  
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
  width: 100%;
  height:100vh;
  
 
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}